import { useRouter } from "next/router"
import getConfig from "next/config"

import Gtm from "lib/gtm"
import { NextSeo } from "next-seo"
import { useTranslation } from "lib/i18n/translation"

import {
	Coil,
	Acquisitions,
	ListsOf,
	MonthList,
	Selections,
	Treasures
} from "components/home"
import { FETCH_PAGE } from "lib/graphql/queries"
import { Query } from "components/common"
import QueryMatcher from "components/layout/query-matcher"

const Home = ({ t, match, randomProducts }) => {
	const router = useRouter()
	const url = router ? router.asPath : null
	const { c } = useTranslation("common")

	return (
		<Query
			query={FETCH_PAGE}
			options={{variables: { query: `linkRewrite.value:"home"` }}}
		>
			{({ cms: { pages: { items: [page = {}] } } }) => (
				<>
					<NextSeo
						title={page.metaTitle}
						titleTemplate="%s"
						description={page.metaDescription}
					/>
					<QueryMatcher match={match}/>
					<Gtm pageType="Home page" pagePath={url} pageTitle={c("home")} />
					<Coil randomProducts={randomProducts} />
					<Acquisitions />
					<ListsOf />
					<MonthList />
					<Selections />
					<Treasures />
				</>
			)}
		</Query>
	)
}

const { publicRuntimeConfig } = getConfig()

const buildHeaders = (req) => {
	if (!req?.headers) {
		return null
	}

	const headers = req.headers

	if (publicRuntimeConfig.bypassHeader) {
		headers["x-lacinetek-staging-secret"] = publicRuntimeConfig.bypassHeader
	}

	return headers
}

Home.getInitialProps = async (ctx) => {
	const domain = ctx.req ? `${ctx.req.protocol}://${ctx.req.get("Host")}` : ""
	const headers = buildHeaders(ctx.req)

	const randomProducts = ctx.req
	? await fetch(`${domain}/fetch-coil-products`, { headers }).then(res => res.json())
	: await fetch(`${domain}/fetch-coil-products`).then(res => res.json())

	return {
		...(await QueryMatcher.getInitialProps(ctx)),
		randomProducts
	}
}

export default Home
